
import { defineComponent } from "vue";
import SettlementEntityDetail from "@/components/page/kyc/settlement-entity/natural-person/Detail.vue";

export default defineComponent({
  name: "settlement-entity-natural-person-detail",
  components: {
    SettlementEntityDetail,
  },
  setup() {
    return {};
  },
});
